.header {
  font-size: 24px;
  color: white;
  background-color: #232f3e;
  box-shadow: 0 -1px 0 #404854 inset;
  padding-top: 20px;
  padding-bottom: 20px;
}

.link {
  display: block;
  text-decoration: none;
}

.category {
  padding-top: 16px;
  padding-bottom: 16px;
}

.categoryText {
  color: white;
}

.listItem {
  padding-top: 1px;
  padding-bottom: 1px;
  color: rgba(255, 255, 255, 0.7);
}

.link--active .listItem,
.listItem:hover,
.listItem:focus {
  color: #4fc3f7;
  background-color: rgba(255, 255, 255, 0.08);
}

.listItemIcon {
  min-width: auto;
  margin-right: 16px;
}

.listItemText {
  color: inherit;
  font-size: inherit;
}

.icon {
  min-width: auto;
  margin-right: 16px;
}
