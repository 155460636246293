.modal {
  padding-top: 10vh;
}

.container {
  margin: 0 auto;
  max-width: 800px;
  outline: none;
}

.content {
  max-height: 80vh;
  overflow: auto;
}
